.section_contact {
  position: relative;
  padding: 100px 0;
  background-color: #153336;

  @include desktop_s {
    padding: 30px 0;
  }
}

.contact_wrap {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include desktop_middle {
    width: 100%;
  }

  @include desktop_s {
    flex-direction: column;
  }
}

.contact_form,
.contact_info {
  width: 42%;

  @include desktop_sm {
    width: 47%;
  }

  @include desktop_s {
    width: 100%;
  }
}

.form_field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  @include phones {
    flex-direction: column;
    align-items: flex-start;
  }
}

.form_input {
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid #fff;

  &:focus {
    outline: transparent;
  }
}

.form_input::-webkit-input-placeholder {color: #fff; font-weight: 500; font-size: 16px;}
.form_input::-moz-placeholder          {color: #fff; font-weight: 500; font-size: 16px;}
.form_input:-moz-placeholder           {color: #fff; font-weight: 500; font-size: 16px;}
.form_input:-ms-input-placeholder      {color: #fff; font-weight: 500; font-size: 16px;}

.checkbox_wrap {
  display: flex;

  @include phones {
    margin-bottom: 15px;
  }
}

.form_checkbox {
  position: relative;
  width: 35px;
  height: 35px;
  flex: 0 0 auto;
  border: 1px solid #fff;
  margin-right: 15px;

  &.checked {
    .jq-checkbox__div {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 15px;
      height: 15px;
      background-color: #fff;
    }
  }
}

.form_label {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
}

.form_submit {
  width: 260px;
  height: 65px;
  color: #153336;
	font-weight: 700;
	border: 4px solid #fff;
	background-color: #fff;
	line-height: normal;
	padding: 0 10px;

	&:hover {
    background-color: #153336;
		color: #fff;
  }
  
  @include phones {
    width: 100%;
  }
}

.contact_info_row {
  display: flex;
  margin-bottom: 40px;

  @include phones {
    flex-direction: column;
  }
}

.contact_info_title {
  flex: 0 0 auto;
  width: 120px;
  margin-right: 20px;

  p {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
  }
}

.contact_info_text {
  p, a {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
  }
}