.modal-dialog {
	max-width: 1000px;
	margin: 5% auto;

	@include desktop-s {
		width: 90%;
	}
}

#orderBuy  {
	.modal-dialog {
		max-width: 640px;
		height: 500px;
		background-color: #fff;

		@include desktop-s {
			width: 90%;
			height: 380px;
		}
	}

	.modal-content {
		width: 100%;
		height: 100%;
	}

	.modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p {
			color: #3B8545;
			line-height: 33px;
			font-size: 28px;
			font-weight: 700;
			text-align: center;
			margin-bottom: 15px;
		}

		span {
			font-size: 22px;
			font-weight: 600;
			color: #3B8545;
			text-align: center;
			line-height: 26px;
		}
	}
}

.modal-content {
	border-radius: 0;
	border: none;
}

.modal-header {
	position: relative;
	padding: 22px 15px;
	border-bottom: none;
	text-align: center;
	border-radius: 0;

	.close {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 30px;
		height: 30px;
		margin: 0;
		background: url("../img/close.png") no-repeat center center;
	}
}

.modal-title {
	color: #153336;
	font-weight: 700;
	font-size: 32px;
	margin: 0 auto;

	@include phones {
		font-size: 27px;
		padding-top: 20px;
	}
}

.modal-body {
	position: relative;

	.main_contacts {
		width: 100%;
	}
}
