.container_inner {
	@include desktop_s {
		padding: 0 5px;
	}
}

section {
	margin-bottom: 70px;

	@include tablets {
		margin-bottom: 20px;
	}
}

h1 {
	font-weight: 700;
	font-size: 42px;
	line-height: 49px;
	color: #3B8545;

	@include phones {
		font-size: 35px;
	}
}

h2 {
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 40px;
	line-height: 33px;

	&.mod_w {
		color: #fff;
		margin-bottom: 50px;
	}
}

h4 {
	font-size: 22px;
	font-weight: 700;
	color: #0F3F44;
	text-align: center;
	line-height: 25px;
	margin-bottom: 40px;
}

.header {
	position: relative;
	z-index: 20;
}

.header.fix {
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	background: #fff;
	z-index: 1000;
	box-shadow: 0 0 15px #000;

	.header_nav {
		margin-bottom: 0;
		border-bottom: 4px solid transparent;
	}
}

.header_nav {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	padding: 10px 0;
	margin-bottom: 70px;
	border-bottom: 4px solid #0F3F44;

	@include desktop_s {
		padding: 15px 0;
	}

	.main_btn {
		margin-left: auto;

		@include desktop_s {
			margin-left: 0;
		}

		@include phones {
			font-size: 13px;
			padding: 5px;
		}
	}
}

.logo {
	position: relative;

	&_header {
		z-index: 95;
	}
}

.logo_link {
	width: 290px;
	display: block;

	img {
		width: 100%;
	}
}

.main_btn {
	color: #3B8545;
	font-weight: 700;
	border: 4px solid #3B8545;
	background-color: #fff;
	line-height: normal;
	padding: 14px 25px;

	&:hover {
		background-color: #3B8545;
		color: #fff;
	}
}

.logo_link_header {
	
	@include desktop_sm {
		width: 170px;
	}

	@include phones {
		width: 140px;
	}
}

.navbar {
	padding: 0;

	@include desktop_s {
		position: absolute;
		right: 0;
	}
}

.navbar-light .navbar-toggler {
	position: relative;
	border: none;
	padding: 0; 
	width: 30px;
	height: 22px;
	z-index: 95;
}

.navbar-light .navbar-toggler-icon {
	background-image: none;
	display: none;
}

.sandwich {
	display: block;
	width: 30px;
	height: 22px;
}

.sw-topper, .sw-bottom, .sw-footer {
	position: relative;
	width: 30px;
	height: 3px;
	background: #0F3F44;
	border: none;
	display: block;
}

.sw-bottom {
	top: 6px;
	transition: transform 0.5s, top 0.2s;
}

.sw-footer {
	top: 12px;
	transition: all 0.5s;
}

.sandwich.active {

	.sw-topper {
		top: 10px;
		background: #0F3F44;
		transform: rotate(-45deg);
	}

	.sw-bottom {
		top: 7px;
		background: #0F3F44;
		transform: rotate(45deg);
	}

	.sw-footer {
		top: 0;
		background: #0F3F44;
		transform: rotate(90deg);
		opacity: 0;
	}
}

.navbar-collapse {

	@include desktop_s {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.95);
		backdrop-filter: blur(4px);
		z-index: 90;
		top: 0;
		left: 0;
	}
}

.navbar-nav_main {
	@include desktop_s {
		display: none;
	}
}

.navbar-nav_mob {
	position: relative;
	display: none;

	@include desktop_s {
		display: block;
		top: 50%;
		transform: translate(0, -50%);
	}

	.nav-item {
		@include desktop_s {
			margin-left: 0;
			text-align: center;
		}
	}

	.nav-link {
		@include desktop_s {
			height: auto !important;
			display: inline-block !important;
			font-weight: bold;
			padding: 13px 5px !important;
		}
	}
}

.navbar-light .navbar-nav .nav-link {
	display: flex;
	align-items: center;
	color: #0F3F44;
	padding: 5px 0;
	font-weight: 700;
	font-size: 20px;
	line-height: normal;
	text-transform: uppercase;
	border-bottom: 2px solid transparent;
	-webkit-transition: 0.5s;
		transition: 0.5s;

	@include desktop_middle {
		font-size: 18px;
	}

	&:hover,
	&.active,
	&:focus {
		color: #0F3F44;
		border-bottom: 2px solid #0F3F44;
		-webkit-transition: 0.5s;
		transition: 0.5s;
	}
}

.nav-item {
	margin-left: 50px;

	@include desktop_middle {
		margin-left: 25px;
	}
}

.phone_b {
	@include desktop_s {
		display: none;
	}
}
