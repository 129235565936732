.product_head {
  width: 350px;

  @include desktop_middle {
    width: 100%;
  }
}

.product_content {
  width: calc(100% - 350px);
  margin-bottom: 70px;
  
  &.mod {
    padding-right: 17%;

    @include desktop_middle {
      padding-right: 0;
    }
  }

  @include desktop_middle {
    width: 100%;
    padding-right: 0;
  }

  @include tablets {
    margin-bottom: 20px;
  }
}

.product_content_text {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  column-gap: 50px;

  @include tablets_s {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    column-gap: 0;
  }

  p {
    margin-bottom: 20px;
  }
}

.effects_row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include desktop_s {
    flex-direction: column;
  }

  &:not(:first-child) {
    margin-top: -50px;

    @include desktop_middle {
      margin-top: -30px;
    }

    @include desktop_s {
      margin-top: 0;
    }
  }
}

.effects_cell {
  width: 48%;
  display: flex;
  align-items: center;

  @include desktop_s {
    width: 100%;
    margin-bottom: 10px;
  }

  @include phones {
    flex-direction: column;
  }
}

.effect_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 183px;
  flex: 0 0 auto;
  background-image: url("../img/hexagon_small.png");
  background-repeat: no-repeat;
  background-size: contain;

  &.light {
    background-image: url("../img/hexagon_small_light.png");
  }

  @include desktop_middle {
    width: 125px;
    height: 139px;
  }

  @include phones {
    margin-bottom: 10px;
  }
}

.box_line {
  position: relative;
  width: 230px;
  height: 6px;
  background-color: #369F45;

  &.light {
    background-color: #6AC068;

    &:after {
      background-image: url("../img/hex_light.png");
    }
  }

  @include desktop_lg {
    width: 125px;
  }

  @include desktop_middle {
    width: 80px;
  }

  @include phones {
    display: none;
  }

  &:after {
    position: absolute;
    content: "";
    right: 0;
    top: -10px;
    width: 22px;
    height: 25px;
    background-image: url("../img/hex.png");
    background-size: contain;
  }
}

.effect_text {
  width: 320px;
  margin-left: 20px;

  @include desktop_s {
    width: 100%;
  }

  @include phones {
    margin-left: 0;
  }

  p {
    font-size: 18px;

    @include desktop_middle {
      font-size: 16px;
      line-height: 22px;
    }

    @include phones {
      text-align: center;
    }
  }
}

.tabs-cont_hide {
  position: absolute;
  left: 0;
  top: 0;
  height:0;
  width:0;
  overflow:hidden;
  z-index: -1;
  opacity: 0;
}

.tech_text {
  margin-bottom: 50px;

  @include tablets {
    margin-bottom: 20px;
  }

  p {
    text-align: center;
  }
}

.tech_tabs {
  display: flex;
  justify-content: space-between;

  @include desktop_sm {
    flex-direction: column;
  }
}

.tech_tabs_list {
  width: 36%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap;

  @include desktop_sm {
    width: 100%;
  }
}

.tech_tabs_cont {
  width: 62%;

  @include desktop_sm {
    width: 100%;
  }
}

.tech_tabs_item {
  width: 48%;
  margin: 10px 0;

  @include desktop_sm {
    width: 24%;
  }

  @include tablets {
    width: 32%;
  }

  @include tablets_s {
    width: 49%;
  }

  @include phones {
    width: 100%;
    margin: 3px 0;
  }

  &.active {
    .tech_tabs_link {
      color: #3B8545;
      background-color: #D0EED4;
      text-decoration: none;
    }
  }
}

.tech_tabs_link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  padding: 5px;
  border: 4px solid #3B8545;
  background-color: #3B8545;

  @include phones {
    height: 50px;
  }

  &:hover {
    color: #3B8545;
    background-color: #D0EED4;
    text-decoration: none;
  }
}

.tech_tabs_cont_item {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablets {
    height: 360px;
  }

  @include phones {
    height: 220px;
  }

  img {
    max-width: 100%;
    height: 100%;
    // object-fit: contain;
  }
}