@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800&display=swap');


@font-face {
	font-family: 'Raleway';
	src: url('../fonts/raleway-regular.eot');
	src: url('../fonts/raleway-regular.eot?#iefix') format('embedded-opentype'),
			url('../fonts/raleway-regular.woff2') format('woff2'),
			url('../fonts/raleway-regular.woff') format('woff'),
			url('../fonts/raleway-regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/raleway-bold.eot');
	src: url('../fonts/raleway-bold.eot?#iefix') format('embedded-opentype'),
			url('../fonts/raleway-bold.woff2') format('woff2'),
			url('../fonts/raleway-bold.woff') format('woff'),
			url('../fonts/raleway-bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/raleway-medium.eot');
	src: url('../fonts/raleway-medium.eot?#iefix') format('embedded-opentype'),
			url('../fonts/raleway-medium.woff2') format('woff2'),
			url('../fonts/raleway-medium.woff') format('woff'),
			url('../fonts/raleway-medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/raleway-semibold.eot');
	src: url('../fonts/raleway-semibold.eot?#iefix') format('embedded-opentype'),
			url('../fonts/raleway-semibold.woff2') format('woff2'),
			url('../fonts/raleway-semibold.woff') format('woff'),
			url('../fonts/raleway-semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
