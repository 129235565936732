.preloder {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #e8e8e8;
	transition: 1s all;
	opacity: 1;
	z-index: 999;
	visibility: visible;

	.loader {
		position: absolute;
		@include centered(both);
		width: 75px;
		height: 75px;
		border: 10px solid #fff;
		border-radius: 50%;
		border-top-color: #131323;
		animation: 1s spin infinite linear;
	}

	&.done {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes spin {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}