html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: 'Raleway', sans-serif;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;
	color: #153336;
	background-color: #fff;
	-webkit-font-smoothing: antialiased;
}

.wrapper {
	// max-width: 1920px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

.main_container {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: auto;
}

.container_inner {
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
	padding: 0 30px;
}

label {
	margin-bottom: 0;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

p {
	margin: 0;
}

a {
	color: #153336;

	&:hover {
		color: #153336;
	}
}

* {
	box-sizing: border-box;
}

button:focus {
	outline: transparent;
}