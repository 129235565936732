.about_wrap {
  display: flex;
  justify-content: space-between;

  @include desktop_middle {
    flex-direction: column;
  }
}

.about_info {
  width: calc(100% - 600px);

  @include desktop_middle {
    width: 100%;
    margin-bottom: 20px;
  }
}

.about_foto {
  width: 600px;

  @include tablets {
    width: 100%;
  }

  img {
    width: 100%;
  }
}

.about_info_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablets {
    flex-direction: column;
    align-items: flex-start;
  }
}

.about_info_item {
  width: 47%;

  @include tablets {
    width: 100%;
    margin-bottom: 15px;
  }

  .text {
    font-size: 22px;
    margin-bottom: 20px;
  }

  span {
    font-size: 18px;
    line-height: 25px;
  }
}

.price_main {
  font-size: 32px;
  color: #3B8545;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}

.price_main_title {
  color: #7B8586;
  font-size: 18px;
  text-align: center;
}

.map_wrap {
  max-height: 500px;

  img {
    width: 100%;
  }
}

.time_wrap {
  display: flex;
  justify-content: space-between;

  @include desktop_sm {
    flex-direction: column;
  }
}

.time_text {
  width: 59%;
  padding-right: 200px;

  @include desktop_lg {
    padding-right: 30px;
  }

  @include desktop_sm {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  p {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    column-gap: 50px;

    @include tablets_s {
      -moz-column-count: 1;
      -webkit-column-count: 1;
      column-count: 1;
    }
  }
}

.time_foto {
  display: flex;
  width: 41%;

  @include desktop_sm {
    width: 100%;
  }
}

.time_foto_col {

  &.small {
    width: 38%;
    margin-right: 4%;

    img {
      width: 100%;
    }
  }

  &.wide {
    width: 58%;

    img {
      width: 100%;
    }
  }
}

.time_foto_link {
  display: block;
  margin-bottom: 7.5%;
}

.about_top_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 0;
  border-top: 6px solid rgba(59, 133, 69, 0.2);
  border-bottom: 6px solid rgba(59, 133, 69, 0.2);

  @include desktop_sm {
    flex-direction: column;
  }
}

.about_top_text {
  width: 45%;
  padding-right: 10%;

  @include desktop_sm {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
}

.about_top_foto {
  width: 55%;

  @include desktop_sm {
    width: 100%;
  }

  img {
    width: 100%;
  }
}

.hero_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include desktop_sm {
    flex-direction: column;
  }
}

.hero_head {
  width: 45%;
  padding-right: 20px;

  @include desktop_sm {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
}

.hero_title {
  width: 35%;

  @include desktop_sm {
    width: 100%;
  }

  h2 {
    color: #3B8545;
    margin-bottom: 0;
  }
}

.about_head {
  width: 250px;
}

.about_content {
  display: flex;
  width: calc(100% - 250px);

  @include desktop_middle {
    width: 100%;
  }

  @include tablets {
    flex-direction: column;
  }
}

.about_content_descr {
  width: 63%;
  padding-right: 170px;

  @include desktop_lg {
    padding-right: 50px;
  }

  @include tablets {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 40px;
    color: #3B8545;

    @include tablets {
      font-size: 28px;
    }
  }
}

.about_content_foto {
  width: 37%;

  @include tablets {
    width: 100%;
  }
}

.about_foto_link {
  display: block;
  width: 100%;
  height: 290px;
  margin-bottom: 25px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about_text {
  p {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.about_text_mod {
  p {
    font-weight: 700;
  }
}

.about_text_half {
  width: 75%;
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  column-gap: 50px;

  @include desktop_sm {
    width: 100%;
  }

  @include tablets_s {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

.page_head {
  h2 {
    font-size: 32px;
    color: #3B8545;
  }
}

ol {
  list-style-type: none;
  counter-reset:  item;
  padding-left: 0;

  li {
    font-size: 18px;
    margin-bottom: 20px;

    &::before {
      content: counters(item,".") " ";
      counter-increment: item;
      margin-right: 5px;
    }
  }
 }
