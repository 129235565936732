@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800&display=swap");
html {
  height: 100%; }

body {
  height: 100%;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #153336;
  background-color: #fff;
  -webkit-font-smoothing: antialiased; }

.wrapper {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }

.main_container {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto; }

.container_inner {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 30px; }

label {
  margin-bottom: 0; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

p {
  margin: 0; }

a {
  color: #153336; }
  a:hover {
    color: #153336; }

* {
  box-sizing: border-box; }

button:focus {
  outline: transparent; }

@font-face {
  font-family: 'Raleway';
  src: url("../fonts/raleway-regular.eot");
  src: url("../fonts/raleway-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-regular.woff2") format("woff2"), url("../fonts/raleway-regular.woff") format("woff"), url("../fonts/raleway-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Raleway';
  src: url("../fonts/raleway-bold.eot");
  src: url("../fonts/raleway-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-bold.woff2") format("woff2"), url("../fonts/raleway-bold.woff") format("woff"), url("../fonts/raleway-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Raleway';
  src: url("../fonts/raleway-medium.eot");
  src: url("../fonts/raleway-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-medium.woff2") format("woff2"), url("../fonts/raleway-medium.woff") format("woff"), url("../fonts/raleway-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Raleway';
  src: url("../fonts/raleway-semibold.eot");
  src: url("../fonts/raleway-semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-semibold.woff2") format("woff2"), url("../fonts/raleway-semibold.woff") format("woff"), url("../fonts/raleway-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

.about_wrap {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 1350px) {
    .about_wrap {
      flex-direction: column; } }

.about_info {
  width: calc(100% - 600px); }
  @media screen and (max-width: 1350px) {
    .about_info {
      width: 100%;
      margin-bottom: 20px; } }

.about_foto {
  width: 600px; }
  @media screen and (max-width: 768px) {
    .about_foto {
      width: 100%; } }
  .about_foto img {
    width: 100%; }

.about_info_inner {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .about_info_inner {
      flex-direction: column;
      align-items: flex-start; } }

.about_info_item {
  width: 47%; }
  @media screen and (max-width: 768px) {
    .about_info_item {
      width: 100%;
      margin-bottom: 15px; } }
  .about_info_item .text {
    font-size: 22px;
    margin-bottom: 20px; }
  .about_info_item span {
    font-size: 18px;
    line-height: 25px; }

.price_main {
  font-size: 32px;
  color: #3B8545;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px; }

.price_main_title {
  color: #7B8586;
  font-size: 18px;
  text-align: center; }

.map_wrap {
  max-height: 500px; }
  .map_wrap img {
    width: 100%; }

.time_wrap {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 1200px) {
    .time_wrap {
      flex-direction: column; } }

.time_text {
  width: 59%;
  padding-right: 200px; }
  @media screen and (max-width: 1600px) {
    .time_text {
      padding-right: 30px; } }
  @media screen and (max-width: 1200px) {
    .time_text {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px; } }
  .time_text p {
    column-count: 2;
    column-gap: 50px; }
    @media screen and (max-width: 620px) {
      .time_text p {
        column-count: 1; } }

.time_foto {
  display: flex;
  width: 41%; }
  @media screen and (max-width: 1200px) {
    .time_foto {
      width: 100%; } }

.time_foto_col.small {
  width: 38%;
  margin-right: 4%; }
  .time_foto_col.small img {
    width: 100%; }

.time_foto_col.wide {
  width: 58%; }
  .time_foto_col.wide img {
    width: 100%; }

.time_foto_link {
  display: block;
  margin-bottom: 7.5%; }

.about_top_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 0;
  border-top: 6px solid rgba(59, 133, 69, 0.2);
  border-bottom: 6px solid rgba(59, 133, 69, 0.2); }
  @media screen and (max-width: 1200px) {
    .about_top_wrap {
      flex-direction: column; } }

.about_top_text {
  width: 45%;
  padding-right: 10%; }
  @media screen and (max-width: 1200px) {
    .about_top_text {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px; } }

.about_top_foto {
  width: 55%; }
  @media screen and (max-width: 1200px) {
    .about_top_foto {
      width: 100%; } }
  .about_top_foto img {
    width: 100%; }

.hero_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .hero_wrap {
      flex-direction: column; } }

.hero_head {
  width: 45%;
  padding-right: 20px; }
  @media screen and (max-width: 1200px) {
    .hero_head {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px; } }

.hero_title {
  width: 35%; }
  @media screen and (max-width: 1200px) {
    .hero_title {
      width: 100%; } }
  .hero_title h2 {
    color: #3B8545;
    margin-bottom: 0; }

.about_head {
  width: 250px; }

.about_content {
  display: flex;
  width: calc(100% - 250px); }
  @media screen and (max-width: 1350px) {
    .about_content {
      width: 100%; } }
  @media screen and (max-width: 768px) {
    .about_content {
      flex-direction: column; } }

.about_content_descr {
  width: 63%;
  padding-right: 170px; }
  @media screen and (max-width: 1600px) {
    .about_content_descr {
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .about_content_descr {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px; } }
  .about_content_descr h2 {
    font-size: 40px;
    color: #3B8545; }
    @media screen and (max-width: 768px) {
      .about_content_descr h2 {
        font-size: 28px; } }

.about_content_foto {
  width: 37%; }
  @media screen and (max-width: 768px) {
    .about_content_foto {
      width: 100%; } }

.about_foto_link {
  display: block;
  width: 100%;
  height: 290px;
  margin-bottom: 25px; }
  .about_foto_link img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.about_text p {
  font-size: 18px;
  margin-bottom: 25px; }

.about_text_mod p {
  font-weight: 700; }

.about_text_half {
  width: 75%;
  column-count: 2;
  column-gap: 50px; }
  @media screen and (max-width: 1200px) {
    .about_text_half {
      width: 100%; } }
  @media screen and (max-width: 620px) {
    .about_text_half {
      column-count: 1; } }

.page_head h2 {
  font-size: 32px;
  color: #3B8545; }

ol {
  list-style-type: none;
  counter-reset: item;
  padding-left: 0; }
  ol li {
    font-size: 18px;
    margin-bottom: 20px; }
    ol li::before {
      content: counters(item, ".") " ";
      counter-increment: item;
      margin-right: 5px; }

.contact_content {
  width: calc(100% - 250px); }
  @media screen and (max-width: 1350px) {
    .contact_content {
      width: 100%; } }

.contact_address {
  margin-bottom: 20px; }

.contact_address_box {
  margin-bottom: 45px; }

.contact_address_row {
  display: flex;
  margin-bottom: 25px; }
  @media screen and (max-width: 620px) {
    .contact_address_row {
      flex-direction: column;
      margin-bottom: 20px; } }

.contact_address_name {
  width: 300px;
  flex: 0 0 auto; }
  @media screen and (max-width: 620px) {
    .contact_address_name {
      width: 100%;
      margin-bottom: 5px; } }
  .contact_address_name p {
    font-size: 22px;
    font-weight: 700; }

.contact_address_date p, .contact_address_date a {
  color: #153336;
  font-size: 22px;
  font-weight: 600; }

.contact_map {
  width: 760px;
  height: 400px;
  margin-bottom: 100px; }
  @media screen and (max-width: 992px) {
    .contact_map {
      width: 100%; } }

.info_desc {
  display: flex;
  flex-wrap: wrap; }

.info_desc_item {
  width: 45%;
  padding-right: 100px;
  margin-bottom: 50px; }
  @media screen and (max-width: 1350px) {
    .info_desc_item {
      width: 50%;
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .info_desc_item {
      width: 100%;
      padding-right: 0;
      margin-bottom: 30px; } }

.contact_info_head {
  margin-bottom: 20px; }
  .contact_info_head p {
    font-size: 22px;
    font-weight: 700; }

.contact_info_item {
  margin-bottom: 20px;
  font-size: 22px; }

.main_contacts {
  width: 900px;
  border: 4px solid #153336;
  padding: 90px 120px; }
  @media screen and (max-width: 992px) {
    .main_contacts {
      width: 100%; } }
  @media screen and (max-width: 768px) {
    .main_contacts {
      padding: 40px; } }
  @media screen and (max-width: 576px) {
    .main_contacts {
      padding: 20px; } }
  .main_contacts .mod_w {
    color: #153336; }
  .main_contacts .form_input {
    color: #153336;
    border-bottom: 4px solid #153336; }
  .main_contacts .form_input::-webkit-input-placeholder {
    color: #153336;
    font-weight: 500;
    font-size: 16px; }
  .main_contacts .form_input::-moz-placeholder {
    color: #153336;
    font-weight: 500;
    font-size: 16px; }
  .main_contacts .form_input:-moz-placeholder {
    color: #153336;
    font-weight: 500;
    font-size: 16px; }
  .main_contacts .form_input:-ms-input-placeholder {
    color: #153336;
    font-weight: 500;
    font-size: 16px; }
  .main_contacts .form_checkbox {
    border: 1px solid #153336; }
    .main_contacts .form_checkbox.checked .jq-checkbox__div {
      background-color: #153336; }
  .main_contacts .form_label {
    color: #153336; }
  .main_contacts .form_submit {
    border: 4px solid #153336;
    background-color: #153336;
    color: #fff; }
    .main_contacts .form_submit:hover {
      background-color: #fff;
      color: #153336; }

.documents_list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin: 0 -15px; }

.documents_item {
  display: flex;
  align-items: center;
  width: calc((100% / 3) - 30px);
  margin: 0 15px 30px;
  border: 1px solid #0F3F44;
  padding: 20px 15px; }
  @media screen and (max-width: 1200px) {
    .documents_item {
      width: calc((100% / 2) - 30px); } }
  @media screen and (max-width: 768px) {
    .documents_item {
      width: 100%; } }

.documents_icon {
  width: 25px;
  flex: 0 0 auto;
  margin-right: 20px; }
  .documents_icon img {
    width: 100%; }

.documents_text {
  margin-right: 10px; }
  .documents_text p {
    font-size: 16px;
    color: #153336;
    line-height: 19px; }

.documents_button {
  margin-left: auto; }

.input_file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.input_file + label {
  width: 90px;
  height: 35px;
  font-size: 14px;
  font-weight: normal;
  color: #3B8545;
  padding: 2px 0 0 40px;
  background-color: #fff;
  display: inline-block;
  border: 1px solid #3B8545;
  background-image: url("../img/upload_icon.svg");
  background-repeat: no-repeat;
  background-position: 20px center; }

.input_file:focus + label,
.input_file + label:hover {
  opacity: .7; }

.input_file + label {
  cursor: pointer; }

.input_file:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px; }

.pagination {
  display: flex;
  justify-content: center;
  padding: 30px 0; }

.page-item {
  margin: 0 10px; }
  @media screen and (max-width: 480px) {
    .page-item {
      margin: 0 10px; } }
  .page-item:first-child .page-link, .page-item:last-child .page-link {
    border-radius: 0; }

.page-link {
  width: 36px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 1px solid #3B8545;
  border-radius: 0;
  color: #3B8545;
  font-size: 24px;
  font-weight: 400; }
  .page-link:hover {
    background-color: #D0EED4;
    border: 1px solid #3B8545;
    color: #3B8545; }
  .page-link.active {
    background-color: #3B8545;
    color: #fff; }

.footer {
  position: relative; }

.footer_inner {
  position: relative;
  display: flex;
  align-items: center;
  padding: 70px 0;
  border-top: 4px solid #0F3F44; }
  @media screen and (max-width: 1200px) {
    .footer_inner {
      flex-direction: column;
      padding: 20px 0; } }

.footer_content {
  width: 100%;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 1200px) {
    .footer_content {
      flex-direction: column; } }

.foot_item {
  max-width: 600px;
  padding-left: 70px; }
  @media screen and (max-width: 1350px) {
    .foot_item {
      padding-left: 30px; } }
  @media screen and (max-width: 1200px) {
    .foot_item {
      max-width: 100%;
      padding-left: 0;
      margin-bottom: 20px; } }

.foot_item_head {
  margin-bottom: 25px; }
  @media screen and (max-width: 1200px) {
    .foot_item_head {
      margin-bottom: 10px; } }
  .foot_item_head p {
    font-size: 22px;
    font-weight: 700; }
    @media screen and (max-width: 1200px) {
      .foot_item_head p {
        text-align: center; } }

.foot_item_cont p {
  font-size: 22px;
  font-weight: 500; }
  @media screen and (max-width: 1200px) {
    .foot_item_cont p {
      text-align: center; } }

.phone_link,
.email_link,
.wts_link {
  display: block;
  font-size: 22px;
  font-weight: 500; }
  @media screen and (max-width: 1200px) {
    .phone_link,
    .email_link,
    .wts_link {
      text-align: center; } }

@media screen and (max-width: 992px) {
  .container_inner {
    padding: 0 5px; } }

section {
  margin-bottom: 70px; }
  @media screen and (max-width: 768px) {
    section {
      margin-bottom: 20px; } }

h1 {
  font-weight: 700;
  font-size: 42px;
  line-height: 49px;
  color: #3B8545; }
  @media screen and (max-width: 480px) {
    h1 {
      font-size: 35px; } }

h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 33px; }
  h2.mod_w {
    color: #fff;
    margin-bottom: 50px; }

h4 {
  font-size: 22px;
  font-weight: 700;
  color: #0F3F44;
  text-align: center;
  line-height: 25px;
  margin-bottom: 40px; }

.header {
  position: relative;
  z-index: 20; }

.header.fix {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 0 15px #000; }
  .header.fix .header_nav {
    margin-bottom: 0;
    border-bottom: 4px solid transparent; }

.header_nav {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 70px;
  border-bottom: 4px solid #0F3F44; }
  @media screen and (max-width: 992px) {
    .header_nav {
      padding: 15px 0; } }
  .header_nav .main_btn {
    margin-left: auto; }
    @media screen and (max-width: 992px) {
      .header_nav .main_btn {
        margin-left: 0; } }
    @media screen and (max-width: 480px) {
      .header_nav .main_btn {
        font-size: 13px;
        padding: 5px; } }

.logo {
  position: relative; }
  .logo_header {
    z-index: 95; }

.logo_link {
  width: 290px;
  display: block; }
  .logo_link img {
    width: 100%; }

.main_btn {
  color: #3B8545;
  font-weight: 700;
  border: 4px solid #3B8545;
  background-color: #fff;
  line-height: normal;
  padding: 14px 25px; }
  .main_btn:hover {
    background-color: #3B8545;
    color: #fff; }

@media screen and (max-width: 1200px) {
  .logo_link_header {
    width: 170px; } }

@media screen and (max-width: 480px) {
  .logo_link_header {
    width: 140px; } }

.navbar {
  padding: 0; }
  @media screen and (max-width: 992px) {
    .navbar {
      position: absolute;
      right: 0; } }

.navbar-light .navbar-toggler {
  position: relative;
  border: none;
  padding: 0;
  width: 30px;
  height: 22px;
  z-index: 95; }

.navbar-light .navbar-toggler-icon {
  background-image: none;
  display: none; }

.sandwich {
  display: block;
  width: 30px;
  height: 22px; }

.sw-topper, .sw-bottom, .sw-footer {
  position: relative;
  width: 30px;
  height: 3px;
  background: #0F3F44;
  border: none;
  display: block; }

.sw-bottom {
  top: 6px;
  transition: transform 0.5s, top 0.2s; }

.sw-footer {
  top: 12px;
  transition: all 0.5s; }

.sandwich.active .sw-topper {
  top: 10px;
  background: #0F3F44;
  transform: rotate(-45deg); }

.sandwich.active .sw-bottom {
  top: 7px;
  background: #0F3F44;
  transform: rotate(45deg); }

.sandwich.active .sw-footer {
  top: 0;
  background: #0F3F44;
  transform: rotate(90deg);
  opacity: 0; }

@media screen and (max-width: 992px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: 90;
    top: 0;
    left: 0; } }

@media screen and (max-width: 992px) {
  .navbar-nav_main {
    display: none; } }

.navbar-nav_mob {
  position: relative;
  display: none; }
  @media screen and (max-width: 992px) {
    .navbar-nav_mob {
      display: block;
      top: 50%;
      transform: translate(0, -50%); } }
  @media screen and (max-width: 992px) {
    .navbar-nav_mob .nav-item {
      margin-left: 0;
      text-align: center; } }
  @media screen and (max-width: 992px) {
    .navbar-nav_mob .nav-link {
      height: auto !important;
      display: inline-block !important;
      font-weight: bold;
      padding: 13px 5px !important; } }

.navbar-light .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  color: #0F3F44;
  padding: 5px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  transition: 0.5s; }
  @media screen and (max-width: 1350px) {
    .navbar-light .navbar-nav .nav-link {
      font-size: 18px; } }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:focus {
    color: #0F3F44;
    border-bottom: 2px solid #0F3F44;
    transition: 0.5s; }

.nav-item {
  margin-left: 50px; }
  @media screen and (max-width: 1350px) {
    .nav-item {
      margin-left: 25px; } }

@media screen and (max-width: 992px) {
  .phone_b {
    display: none; } }

.modal-dialog {
  max-width: 1000px;
  margin: 5% auto; }
  @media screen and (max-width: 992px) {
    .modal-dialog {
      width: 90%; } }

#orderBuy .modal-dialog {
  max-width: 640px;
  height: 500px;
  background-color: #fff; }
  @media screen and (max-width: 992px) {
    #orderBuy .modal-dialog {
      width: 90%;
      height: 380px; } }

#orderBuy .modal-content {
  width: 100%;
  height: 100%; }

#orderBuy .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  #orderBuy .modal-body p {
    color: #3B8545;
    line-height: 33px;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px; }
  #orderBuy .modal-body span {
    font-size: 22px;
    font-weight: 600;
    color: #3B8545;
    text-align: center;
    line-height: 26px; }

.modal-content {
  border-radius: 0;
  border: none; }

.modal-header {
  position: relative;
  padding: 22px 15px;
  border-bottom: none;
  text-align: center;
  border-radius: 0; }
  .modal-header .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    margin: 0;
    background: url("../img/close.png") no-repeat center center; }

.modal-title {
  color: #153336;
  font-weight: 700;
  font-size: 32px;
  margin: 0 auto; }
  @media screen and (max-width: 480px) {
    .modal-title {
      font-size: 27px;
      padding-top: 20px; } }

.modal-body {
  position: relative; }
  .modal-body .main_contacts {
    width: 100%; }

.graphs_box {
  width: 400px; }
  @media screen and (max-width: 480px) {
    .graphs_box {
      width: 320px; } }

.graphs_wrap {
  margin-bottom: 70px; }

.graphs_list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }

.graphs_title {
  margin-bottom: 30px; }
  .graphs_title p {
    font-weight: 700; }

.circle_wrap {
  display: flex;
  justify-content: space-between; }

.circles-wrp {
  margin-bottom: 20px; }

.circles-text {
  display: flex !important;
  align-items: center !important;
  font-size: 23px !important;
  font-weight: 700;
  padding: 0 87px !important;
  line-height: 1.2 !important; }

@media screen and (max-width: 768px) {
  .table_info_wrap {
    overflow-y: auto;
    overflow: auto; } }

.table_info {
  display: table;
  width: 100%; }
  @media screen and (max-width: 768px) {
    .table_info {
      min-width: 745px; } }

.table_info_row {
  display: table-row; }

.table_info_cell {
  width: 25%;
  display: table-cell;
  padding: 20px 0;
  border-bottom: 5px solid rgba(59, 133, 69, 0.2);
  font-size: 18px;
  line-height: 21px; }
  @media screen and (max-width: 1200px) {
    .table_info_cell {
      font-size: 16px; } }
  @media screen and (max-width: 992px) {
    .table_info_cell {
      padding: 10px 0; } }
  .table_info_cell:not(:first-child) {
    padding: 10px;
    text-align: center; }

.table_info_row_head .table_info_cell {
  font-size: 22px;
  font-weight: 600;
  border-bottom: none; }
  @media screen and (max-width: 1200px) {
    .table_info_row_head .table_info_cell {
      font-size: 18px; } }

.preloder {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e8e8e8;
  transition: 1s all;
  opacity: 1;
  z-index: 999;
  visibility: visible; }
  .preloder .loader {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 75px;
    border: 10px solid #fff;
    border-radius: 50%;
    border-top-color: #131323;
    animation: 1s spin infinite linear; }
  .preloder.done {
    opacity: 0;
    visibility: hidden; }

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg); }
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

.product_head {
  width: 350px; }
  @media screen and (max-width: 1350px) {
    .product_head {
      width: 100%; } }

.product_content {
  width: calc(100% - 350px);
  margin-bottom: 70px; }
  .product_content.mod {
    padding-right: 17%; }
    @media screen and (max-width: 1350px) {
      .product_content.mod {
        padding-right: 0; } }
  @media screen and (max-width: 1350px) {
    .product_content {
      width: 100%;
      padding-right: 0; } }
  @media screen and (max-width: 768px) {
    .product_content {
      margin-bottom: 20px; } }

.product_content_text {
  column-count: 2;
  column-gap: 50px; }
  @media screen and (max-width: 620px) {
    .product_content_text {
      column-count: 1;
      column-gap: 0; } }
  .product_content_text p {
    margin-bottom: 20px; }

.effects_row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 992px) {
    .effects_row {
      flex-direction: column; } }
  .effects_row:not(:first-child) {
    margin-top: -50px; }
    @media screen and (max-width: 1350px) {
      .effects_row:not(:first-child) {
        margin-top: -30px; } }
    @media screen and (max-width: 992px) {
      .effects_row:not(:first-child) {
        margin-top: 0; } }

.effects_cell {
  width: 48%;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 992px) {
    .effects_cell {
      width: 100%;
      margin-bottom: 10px; } }
  @media screen and (max-width: 480px) {
    .effects_cell {
      flex-direction: column; } }

.effect_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 183px;
  flex: 0 0 auto;
  background-image: url("../img/hexagon_small.png");
  background-repeat: no-repeat;
  background-size: contain; }
  .effect_title.light {
    background-image: url("../img/hexagon_small_light.png"); }
  @media screen and (max-width: 1350px) {
    .effect_title {
      width: 125px;
      height: 139px; } }
  @media screen and (max-width: 480px) {
    .effect_title {
      margin-bottom: 10px; } }

.box_line {
  position: relative;
  width: 230px;
  height: 6px;
  background-color: #369F45; }
  .box_line.light {
    background-color: #6AC068; }
    .box_line.light:after {
      background-image: url("../img/hex_light.png"); }
  @media screen and (max-width: 1600px) {
    .box_line {
      width: 125px; } }
  @media screen and (max-width: 1350px) {
    .box_line {
      width: 80px; } }
  @media screen and (max-width: 480px) {
    .box_line {
      display: none; } }
  .box_line:after {
    position: absolute;
    content: "";
    right: 0;
    top: -10px;
    width: 22px;
    height: 25px;
    background-image: url("../img/hex.png");
    background-size: contain; }

.effect_text {
  width: 320px;
  margin-left: 20px; }
  @media screen and (max-width: 992px) {
    .effect_text {
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .effect_text {
      margin-left: 0; } }
  .effect_text p {
    font-size: 18px; }
    @media screen and (max-width: 1350px) {
      .effect_text p {
        font-size: 16px;
        line-height: 22px; } }
    @media screen and (max-width: 480px) {
      .effect_text p {
        text-align: center; } }

.tabs-cont_hide {
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: -1;
  opacity: 0; }

.tech_text {
  margin-bottom: 50px; }
  @media screen and (max-width: 768px) {
    .tech_text {
      margin-bottom: 20px; } }
  .tech_text p {
    text-align: center; }

.tech_tabs {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 1200px) {
    .tech_tabs {
      flex-direction: column; } }

.tech_tabs_list {
  width: 36%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .tech_tabs_list {
      width: 100%; } }

.tech_tabs_cont {
  width: 62%; }
  @media screen and (max-width: 1200px) {
    .tech_tabs_cont {
      width: 100%; } }

.tech_tabs_item {
  width: 48%;
  margin: 10px 0; }
  @media screen and (max-width: 1200px) {
    .tech_tabs_item {
      width: 24%; } }
  @media screen and (max-width: 768px) {
    .tech_tabs_item {
      width: 32%; } }
  @media screen and (max-width: 620px) {
    .tech_tabs_item {
      width: 49%; } }
  @media screen and (max-width: 480px) {
    .tech_tabs_item {
      width: 100%;
      margin: 3px 0; } }
  .tech_tabs_item.active .tech_tabs_link {
    color: #3B8545;
    background-color: #D0EED4;
    text-decoration: none; }

.tech_tabs_link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  padding: 5px;
  border: 4px solid #3B8545;
  background-color: #3B8545; }
  @media screen and (max-width: 480px) {
    .tech_tabs_link {
      height: 50px; } }
  .tech_tabs_link:hover {
    color: #3B8545;
    background-color: #D0EED4;
    text-decoration: none; }

.tech_tabs_cont_item {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 768px) {
    .tech_tabs_cont_item {
      height: 360px; } }
  @media screen and (max-width: 480px) {
    .tech_tabs_cont_item {
      height: 220px; } }
  .tech_tabs_cont_item img {
    max-width: 100%;
    height: 100%; }

.section_contact {
  position: relative;
  padding: 100px 0;
  background-color: #153336; }
  @media screen and (max-width: 992px) {
    .section_contact {
      padding: 30px 0; } }

.contact_wrap {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 1350px) {
    .contact_wrap {
      width: 100%; } }
  @media screen and (max-width: 992px) {
    .contact_wrap {
      flex-direction: column; } }

.contact_form,
.contact_info {
  width: 42%; }
  @media screen and (max-width: 1200px) {
    .contact_form,
    .contact_info {
      width: 47%; } }
  @media screen and (max-width: 992px) {
    .contact_form,
    .contact_info {
      width: 100%; } }

.form_field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px; }
  @media screen and (max-width: 480px) {
    .form_field {
      flex-direction: column;
      align-items: flex-start; } }

.form_input {
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid #fff; }
  .form_input:focus {
    outline: transparent; }

.form_input::-webkit-input-placeholder {
  color: #fff;
  font-weight: 500;
  font-size: 16px; }

.form_input::-moz-placeholder {
  color: #fff;
  font-weight: 500;
  font-size: 16px; }

.form_input:-moz-placeholder {
  color: #fff;
  font-weight: 500;
  font-size: 16px; }

.form_input:-ms-input-placeholder {
  color: #fff;
  font-weight: 500;
  font-size: 16px; }

.checkbox_wrap {
  display: flex; }
  @media screen and (max-width: 480px) {
    .checkbox_wrap {
      margin-bottom: 15px; } }

.form_checkbox {
  position: relative;
  width: 35px;
  height: 35px;
  flex: 0 0 auto;
  border: 1px solid #fff;
  margin-right: 15px; }
  .form_checkbox.checked .jq-checkbox__div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 15px;
    background-color: #fff; }

.form_label {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none; }

.form_submit {
  width: 260px;
  height: 65px;
  color: #153336;
  font-weight: 700;
  border: 4px solid #fff;
  background-color: #fff;
  line-height: normal;
  padding: 0 10px; }
  .form_submit:hover {
    background-color: #153336;
    color: #fff; }
  @media screen and (max-width: 480px) {
    .form_submit {
      width: 100%; } }

.contact_info_row {
  display: flex;
  margin-bottom: 40px; }
  @media screen and (max-width: 480px) {
    .contact_info_row {
      flex-direction: column; } }

.contact_info_title {
  flex: 0 0 auto;
  width: 120px;
  margin-right: 20px; }
  .contact_info_title p {
    font-size: 22px;
    font-weight: 700;
    color: #fff; }

.contact_info_text p, .contact_info_text a {
  font-size: 22px;
  font-weight: 500;
  color: #fff; }

.news_slide .slick-prev {
  left: 140px; }

.news_slide .slick-next {
  left: 200px; }

.news_slide_item {
  margin: 0 10px; }

.news_link {
  display: block;
  position: relative;
  width: 100%;
  height: 440px;
  transition: all .5s; }
  .news_link:hover .overlay {
    opacity: 1;
    transition: all .5s; }
  .news_link:active .overlay {
    background: linear-gradient(180deg, rgba(21, 51, 54, 0) 0%, rgba(59, 133, 69, 0.7) 100%); }
  .news_link img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .news_link .overlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 9px solid #3B8545; }

.news_content {
  position: absolute;
  bottom: 25px;
  left: 0;
  padding: 0 25px; }
  @media screen and (max-width: 480px) {
    .news_content {
      padding: 0 10px; } }

.news_head {
  font-size: 18px;
  color: #fff;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 20px; }

.news_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  border-top: 4px solid #fff; }

.news_title,
.news_date {
  color: #fff;
  font-weight: 500;
  font-size: 14px; }

.advantages_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin: 0 -15px 50px; }
  @media screen and (max-width: 620px) {
    .advantages_list {
      width: 100%;
      margin: 0 0 50px 0; } }

.advantages_item {
  width: calc((100% / 4) - 30px);
  margin: 0 15px; }
  @media screen and (max-width: 1200px) {
    .advantages_item {
      width: calc((100% / 2) - 30px);
      margin: 0 15px 20px 15px; } }
  @media screen and (max-width: 620px) {
    .advantages_item {
      width: 100%;
      margin: 0 0 20px 0; } }

.advantages_total {
  margin-bottom: 30px; }
  @media screen and (max-width: 480px) {
    .advantages_total {
      margin-bottom: 15px; } }
  .advantages_total p {
    font-size: 60px;
    font-weight: 700;
    color: #3B8545;
    line-height: 45px; }
    @media screen and (max-width: 1600px) {
      .advantages_total p {
        font-size: 50px; } }
    @media screen and (max-width: 480px) {
      .advantages_total p {
        font-size: 40px; } }

.advantages_text p {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px; }
  @media screen and (max-width: 480px) {
    .advantages_text p {
      font-size: 20px; } }

.offer_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 0 -10px; }
  @media screen and (max-width: 620px) {
    .offer_list {
      width: 100%;
      margin: 0; } }

.offer_list_item {
  display: flex;
  align-items: center;
  width: calc((100% / 4) - 20px);
  margin: 0 10px;
  padding: 30px 25px;
  background-color: #3B8545; }
  @media screen and (max-width: 1200px) {
    .offer_list_item {
      width: calc((100% / 2) - 20px);
      margin-bottom: 20px; } }
  @media screen and (max-width: 620px) {
    .offer_list_item {
      width: 100%;
      margin: 0 0 20px 0; } }
  .offer_list_item:nth-child(even) {
    background-color: #4AA248; }

.offer_icon {
  width: 74px;
  flex: 0 0 auto;
  margin-right: 25px; }

.offer_cont p {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 23px;
  padding-bottom: 15px;
  margin-bottom: 15px; }
  .offer_cont p:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -5px;
    width: 80px;
    height: 4px;
    background-color: #fff; }

.offer_cont span {
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  color: #fff; }

.section_reviews {
  position: relative; }

.reviews_slide {
  position: relative; }

.slick-arrow {
  width: 44px;
  height: 44px;
  padding: 0;
  background-color: #fff;
  border: 2px solid #3B8545;
  text-indent: -9999px;
  z-index: 10; }

.slick-arrow {
  position: absolute;
  top: -75px; }

.slick-prev {
  left: 120px;
  background-image: url("../img/arrow_left.svg");
  background-repeat: no-repeat;
  background-position: center; }

.slick-next {
  left: 180px;
  background-image: url("../img/arrow_right.svg");
  background-repeat: no-repeat;
  background-position: center; }

.reviews_slide_item {
  position: relative;
  background-color: #F1F9F2;
  padding: 35px 20px;
  margin: 0 10px; }

.reviews_top {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }
  @media screen and (max-width: 480px) {
    .reviews_top {
      flex-direction: column;
      align-items: flex-start; } }

.reviews_logo {
  flex: 0 0 auto;
  max-width: 120px;
  margin-right: 15px; }
  @media screen and (max-width: 480px) {
    .reviews_logo {
      margin: 0 0 10px 0; } }
  .reviews_logo img {
    max-width: 100%; }

.reviews_title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700; }

.reviews_cont p {
  font-size: 16px;
  line-height: 23px; }

.partenrs_slide {
  position: relative; }
  .partenrs_slide .slick-prev {
    left: 160px; }
  .partenrs_slide .slick-next {
    left: 220px; }

.partenrs_slide_item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 265px;
  background-color: #F1F9F2;
  margin: 0 10px; }
  @media screen and (max-width: 1024px) {
    .partenrs_slide_item {
      height: 200px; } }
  @media screen and (max-width: 480px) {
    .partenrs_slide_item {
      height: 140px; } }
  .partenrs_slide_item img {
    max-width: 90%;
    max-height: 90%; }

@media screen and (max-width: 768px) {
  .stage_box {
    display: none; } }

.hexagon_wrapper {
  width: 1625px;
  margin: 0 auto; }
  @media screen and (max-width: 1700px) {
    .hexagon_wrapper {
      width: 1540px; } }
  @media screen and (max-width: 1600px) {
    .hexagon_wrapper {
      width: 1300px; } }
  @media screen and (max-width: 1350px) {
    .hexagon_wrapper {
      width: 1100px; } }
  @media screen and (max-width: 1200px) {
    .hexagon_wrapper {
      width: 950px; } }
  @media screen and (max-width: 992px) {
    .hexagon_wrapper {
      width: 740px; } }
  @media screen and (max-width: 768px) {
    .hexagon_wrapper {
      width: 100%; } }

.hexagon {
  position: relative;
  width: 389px;
  height: 346px; }
  @media screen and (max-width: 1700px) {
    .hexagon {
      width: 377px;
      height: 336px; } }
  @media screen and (max-width: 1600px) {
    .hexagon {
      width: 330px;
      height: 294px; } }

.hexagon_row {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.hexagon_row_s {
  width: 1010px;
  margin: 0 auto; }
  @media screen and (max-width: 1700px) {
    .hexagon_row_s {
      width: 960px; } }
  @media screen and (max-width: 1600px) {
    .hexagon_row_s {
      width: 815px; } }
  @media screen and (max-width: 1200px) {
    .hexagon_row_s {
      width: 750px; } }
  @media screen and (max-width: 992px) {
    .hexagon_row_s {
      width: 670px; } }

.hexagon_row_up {
  position: relative;
  margin-top: -145px; }
  @media screen and (max-width: 1600px) {
    .hexagon_row_up {
      margin-top: -105px; } }
  @media screen and (max-width: 1350px) {
    .hexagon_row_up {
      margin-top: 10px; } }

.hexagon_row_single {
  justify-content: center; }

.hexagon_foto {
  width: 100%; }

.hexagon_inner {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%); }
  .hexagon_inner p {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    line-height: 23px; }
    @media screen and (max-width: 1600px) {
      .hexagon_inner p {
        font-size: 15px;
        line-height: 21px; } }

.hex_small {
  position: absolute;
  width: 101px;
  height: 89px; }
  @media screen and (max-width: 1600px) {
    .hex_small {
      width: 70px;
      height: 62px; } }
  .hex_small:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
    @media screen and (max-width: 1600px) {
      .hex_small:before {
        background-size: 55%; } }
  .hex_small img {
    width: 100%; }

.hexagon_nubmer {
  position: absolute;
  width: 180px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 20px 0;
  border-top: 3px solid #fff; }
  @media screen and (max-width: 1600px) {
    .hexagon_nubmer {
      padding: 5px 0; } }
  .hexagon_nubmer p {
    font-size: 51px;
    color: #fff;
    font-weight: 600;
    text-align: center; }
    @media screen and (max-width: 1600px) {
      .hexagon_nubmer p {
        font-size: 35px; } }

.hexagon_one .hex_small {
  left: 10px;
  top: 50%;
  transform: translate(0, -50%); }
  .hexagon_one .hex_small:before {
    background-image: url("../img/stage_icon01.png");
    background-repeat: no-repeat;
    background-position: center; }

.hexagon_one .hexagon_inner {
  left: 120px;
  padding-right: 30px; }
  @media screen and (max-width: 1600px) {
    .hexagon_one .hexagon_inner {
      left: 95px; } }

.hexagon_two .hex_small {
  right: 10px;
  top: 50%;
  transform: translate(0, -50%); }
  .hexagon_two .hex_small:before {
    background-image: url("../img/stage_icon02.png");
    background-repeat: no-repeat;
    background-position: center; }

.hexagon_two .hexagon_inner {
  left: 30px;
  padding-right: 120px; }

.hexagon_three .hex_small {
  right: 76px;
  top: 12px; }
  .hexagon_three .hex_small:before {
    background-image: url("../img/stage_icon03.png");
    background-repeat: no-repeat;
    background-position: center; }

.hexagon_three .hexagon_inner {
  padding: 0 50px; }

.hexagon_four .hex_small {
  top: 12px;
  left: 50%;
  transform: translate(-50%, 0); }
  .hexagon_four .hex_small:before {
    background-image: url("../img/stage_icon04.png");
    background-repeat: no-repeat;
    background-position: center; }

.hexagon_four .hexagon_inner {
  padding: 0 28px;
  padding-top: 20px; }
  .hexagon_four .hexagon_inner p {
    font-size: 14px;
    line-height: 20px;
    text-align: center; }

.hexagon_five .hex_small {
  top: 12px;
  left: 76px; }
  .hexagon_five .hex_small:before {
    background-image: url("../img/stage_icon05.png");
    background-repeat: no-repeat;
    background-position: center; }

.hexagon_five .hexagon_inner {
  padding: 0 50px; }

.hexagon_six .hex_small {
  left: 10px;
  top: 50%;
  transform: translate(0, -50%); }
  .hexagon_six .hex_small:before {
    background-image: url("../img/stage_icon06.png");
    background-repeat: no-repeat;
    background-position: center; }

.hexagon_six .hexagon_inner {
  left: 120px;
  padding-right: 30px; }
  @media screen and (max-width: 1600px) {
    .hexagon_six .hexagon_inner {
      left: 95px; } }

.hexagon_seven .hex_small {
  right: 10px;
  top: 50%;
  transform: translate(0, -50%); }
  .hexagon_seven .hex_small:before {
    background-image: url("../img/stage_icon07.png");
    background-repeat: no-repeat;
    background-position: center; }

.hexagon_seven .hexagon_inner {
  left: 30px;
  padding-right: 120px; }

.hexagon_center {
  width: 257px; }
  @media screen and (max-width: 1350px) {
    .hexagon_center {
      width: 200px; } }
  @media screen and (max-width: 992px) {
    .hexagon_center {
      display: none; } }
  .hexagon_center img {
    width: 100%; }
