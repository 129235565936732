.contact_content {
  width: calc(100% - 250px);

  @include desktop_middle {
    width: 100%;
  }
}

.contact_address {
  margin-bottom: 20px;
}

.contact_address_box {
  margin-bottom: 45px;
}

.contact_address_row {
  display: flex;
  margin-bottom: 25px;

  @include tablets_s {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.contact_address_name {
  width: 300px;
  flex: 0 0 auto;

  @include tablets_s {
    width: 100%;
    margin-bottom: 5px;
  }

  p {
    font-size: 22px;
    font-weight: 700;
  }
}

.contact_address_date {
  p, a {
    color: #153336;
    font-size: 22px;
    font-weight: 600;
  }
}

.contact_map {
  width: 760px;
  height: 400px;
  margin-bottom: 100px;

  @include desktop_s {
    width: 100%;
  }
}

.info_desc {
  display: flex;
  flex-wrap: wrap;
}

.info_desc_item {
  width: 45%;
  padding-right: 100px;
  margin-bottom: 50px;

  @include desktop_middle {
    width: 50%;
    padding-right: 30px;
  }

  @include tablets {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
}

.contact_info_head {
  margin-bottom: 20px;

  p {
    font-size: 22px;
    font-weight: 700;
  }
}

.contact_info_item {
  margin-bottom: 20px;
  font-size: 22px;
}

.main_contacts {
  width: 900px;
  border: 4px solid #153336;
  padding: 90px 120px;

  @include desktop_s {
    width: 100%;
  }

  @include tablets {
    padding: 40px;
  }

  @include phones_lg {
    padding: 20px;
  }

  .mod_w {
    color: #153336;
  }

  .form_input {
    color: #153336;
    border-bottom: 4px solid #153336;;
  }

  .form_input::-webkit-input-placeholder {color: #153336; font-weight: 500; font-size: 16px;}
  .form_input::-moz-placeholder          {color: #153336; font-weight: 500; font-size: 16px;}
  .form_input:-moz-placeholder           {color: #153336; font-weight: 500; font-size: 16px;}
  .form_input:-ms-input-placeholder      {color: #153336; font-weight: 500; font-size: 16px;}

  .form_checkbox {
    border: 1px solid #153336;

    &.checked {
      .jq-checkbox__div {
        background-color: #153336;
      }
    }
  }

  .form_label {
    color: #153336;
  }

  .form_submit {
    border: 4px solid #153336;
    background-color: #153336;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: #153336;
    }
  }
}